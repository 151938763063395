@import 'public/styles/scss/layout/shared.scss';

/********************** Header Start **********************/
.header {
  padding: 20px 0px;
  width: 100%;
  z-index: 111;
  background: #fff;
  transition: all 0.5s;
  overflow: unset;
}

.header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #ecf0f4;
  transition: all 0.5s;
}

.header_menu_wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 993px) {
  .headerFixed {
    transition: all 0.5s;
    position: fixed;
    top: 0px;
    z-index: 9999;
  }

  .header_wrap {
    height: 60px;
  }

  .headerFix {
    height: 0px;
    overflow: hidden;
    visibility: hidden;
    padding: 0;
    border: none;
    transition: all 0.5s;
  }

  .headerMenuFix {
    padding: 0px;
  }
}

.cstm_ul_head {
  list-style: none;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0;
}

.style_logo_img {
  width: 150px;
}

.head_menu_seprater {
  background: #dee4eb;
  height: 27px;
  width: 1px;
  display: block;
  color: transparent;
}

.menu_link {
  color: #5b697b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  letter-spacing: -0.3px;
  text-decoration: none;
}

.for_mob {
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
}

.menu_bars {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.menu_bars_span {
  width: 20px;
  height: 2px;
  background: #304054;
  transition: all 0.6s;
  position: relative;
  top: 0px;
}

.menu_bars_span:nth-child(2) {
  width: 14px;
}

.menu_open .menu_bars_span {
  transition: all 0.6s;
  top: 5px;
}

.menu_open .menu_bars_span:nth-child(2) {
  opacity: 0;
}

.menu_open .menu_bars_span:nth-child(1) {
  transform: rotate(45deg) translate(4px, 1px);
}

.menu_open .menu_bars_span:nth-child(3) {
  transform: rotate(-47deg) translate(9px, -5px);
}

.menu_link {
  display: flex;
  gap: 8px;
  color: #112135;
}

.menu_link_i {
  display: flex;
}

.header_menu_ul {
  display: flex;
  padding: 0px;
  gap: 24px;
}

.menus_link {
  color: #304054;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: -0.3px;
}

.has_submenu {
  position: relative;
}

.sub_menu {
  padding: 6px 0px;
  border-radius: 16px;
  border: 1px solid rgba(207, 200, 196, 0.3);
  background: #fff;
  box-shadow: 0px 24px 68px 0px rgba(208, 192, 182, 0.34);
  position: absolute;
  min-width: 190px;
  top: 40px;
  z-index: 11;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

@media screen and (max-width: 992px) {
  .for_desk_menu {
    display: none;
  }


  .cstm_ul_head {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .has_submenu:hover .sub_menu {
    opacity: 1;
    visibility: visible;
    z-index: 99999;
  }

  .menu_item:hover > .menus_link:before {
    transform: rotate(-180deg);
    transition: all 0.6s;
  }
}

@media screen and (max-width: 767px) {
  .sub_menu {
    position: absolute;
    top: 33px;
    max-width: 100%;
    z-index: 111;
    width: 100%;
  }

  .menus_link {
    width: 100%;
    display: block;
  }

  .menu.for_desk {
    display: none;
  }
}

.menu_item.active > .menus_link:before {
  transform: rotate(-180deg);
  transition: all 0.6s;
}

.sub_menu_link {
  padding: 6px 16px;
  line-height: 171.429%;
  font-size: 14px;
  display: flex;
}

.sub_menu_link:hover {
  background: #eee;
}

.menu_more:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 10px;
  background: url('/images/arrow-bottom.svg');
  right: -20px;
  top: 10px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.6s;
}

.search_block {
  display: flex;
  position: relative;
}

.search_btn {
  width: 46px;
  min-width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  z-index: 11;
  border: 1px solid #f1efed;
}

.search_open {
  overflow: hidden;
}

.search_modal {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.search_modal.active {
  opacity: 1;
  visibility: visible;
}

.modal_inner_container {
  max-width: 72%;
  width: 100%;
  margin: auto;
  position: relative;
  top: 20px;
  background: #fff;
  padding: 30px;
  border-radius: 13px;
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.search_modal.active .modal_inner_container {
  transform: translate(0, 0);
  opacity: 1;
}

.search_form_wrapper {
  padding: 25px;
  max-width: 920px;
  margin: auto;
  width: 100%;
}

.input_group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.search_modal_input {
  border: 0;
  border-bottom: 1px solid #d4d3d5;
  padding: 20px 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
  color: #000;
  margin-bottom: 2.1875rem;
  margin: 0;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  outline: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.search_group {
  border-bottom: 1px solid #d4d3d5;
}

.search_submit {
  display: flex;
  align-items: center;
  padding: 20px 0 20px 20px;
  height: 100%;
  background: transparent;
  border: none;
}

.search_submit_img {
  width: 24px;
}

.search_close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.search_close_svg {
  width: 30px;
  height: 30px;
}

.quote_search {
  display: flex;
}

.quote_manage {
  display: flex;
  gap: 12px;
}

.search_field {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.quote_links {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.property_manage {
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  border: 1px solid #304054;
  color: #304054;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.3px;
  transition: 0.3s;
}

.property_manage:hover {
  background-color: #000;
  color: #fff;
  transition: 0.3s;
}

.fet_a_quote {
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  border: 1px solid #304054;
  color: #304054;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.3px;
  transition: 0.3s;
}

.fet_a_quote:hover {
  background-color: #000;
  color: #fff;
  transition: 0.3s;
}

.book_btn {
  padding: 13px 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: #f84069;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.3px;
}

@media screen and (max-width: 767px) {
  div.search_field {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .search_block .search_btn {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .header_wrap .for_mob {
    gap: 22px;
  }
}

@media screen and (max-width: 420px) {
  .search_block .search-fields.open {
    width: 240px;
    left: -193px;
  }
}

@media (min-width: 1801px) {
}

@media screen and (min-width: 993px) {
  .for_mob {
    display: none;
  }

  .for_mobile {
    display: none;
  }
}

@media screen and (min-width: 1141px) and (max-width: 1220px) {
  .header_menu_ul {
    gap: 20px;
  }

  .menus_link {
    font-size: 15px;
  }

  .property_manage {
    padding: 10px 20px;
    font-size: 15px;
  }

  .fet_a_quote {
    padding: 10px 20px;
    font-size: 15px;
  }

  .book_btn {
    padding: 10px 20px;
    font-size: 15px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1140px) {
  .header_menu_ul {
    gap: 15px;
  }

  .menus_link {
    font-size: 15px;
  }

  .property_manage {
    padding: 10px 14px;
    font-size: 14px;
  }

  .fet_a_quote {
    padding: 10px 14px;
    font-size: 14px;
  }

  .book_btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .header {
    padding: 20px 0px 0px;
    position: relative;
    /* position: fixed; */
    z-index: 1;
  }

  .header_menu_ul {
    flex-direction: column;
    gap: 20px !important;
    background: #fff;
  }

  .header_menu_wrap {
    height: 0;
    transition: all 0.6s;
    overflow: hidden;
  }

  .header_menu_wrap.open {
    transition: all 0.6s;
    overflow: auto;
    height: calc(100vh - 100px);
    align-items: baseline;
    padding-bottom: 10px;
  }

  .open-active {
    height: calc(100% - 1px);
  }

  .header_menu {
    width: 100%;
    background: #fff;
  }

  .quote_search {
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 11;
  }

  .quote_manage {
    width: 100%;
    flex-direction: column;
    gap: 16px !important;
  }

  .quote_links {
    width: 100%;
  }

  .quote_links_li {
    width: 100%;
  }

  .cmn_quote_links {
    width: 100%;
  }

  .menu_more:before {
    right: 0px !important;
  }

  .hide_for_tab {
    display: none;
  }

  .header_wrap {
    padding-bottom: 8px;
  }

  .for_desk_open {
    display: none !important;
  }

  .for_mob-user {
    position: relative;
    padding-top: 20px;
    border-top: 1px solid #f4f4f6;
    /* margin-top: 10px; */
    z-index: 1;
  }

  .sub_menu_link {
    width: 100%;
    display: block;
    padding: 9px 0px !important;
    font-size: 16px !important;
    background: transparent !important;
  }

  .sub_menu {
    position: initial;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-top: -12px;
  }

  .menu_more {
    display: none !important;
  }

  .search_form_wrapper {
    padding: 18px 0px;
  }

  .search_modal_input {
    padding: 9px 0 !important;
    font-size: 20px !important;
  }

  .search_submit {
    padding: 10px 0 20px 10px;
  }

  .modal_inner_container {
    max-width: 93%;
  }

  .input_group {
    margin-top: 5px;
  }
}

@media screen and (min-width: 768px) and (max-height: 992px) {
  .for_desk {
    display: block !important;
  }

  .menu.for_mobile {
    display: none;
  }
}

@media screen and (max-height: 767px) {
  .for_desk {
    display: none;
  }

  .header_wrap {
    align-items: flex-start;
  }
}

@media screen and (max-height: 640px) {
  .open-active {
    overflow: auto;
  }
}

@media screen and (max-width: 640px) {
  .quote_links {
    flex-direction: column;
    gap: 16px !important;
  }

  .for_mob {
    gap: 12px;
  }
}

@media screen and (max-width: 420px) {
  .mobile_ul {
    flex-direction: column;
  }

  .header_menu_wrap.open {
    height: calc(100vh - 90px);
  }
}

/********************** Header End **********************/
